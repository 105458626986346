/*
===============================================================================
SPECIFIC DEFINITION - BASKET PAGE
-------------------------------------------------------------------------------
Specification of wrappers for modules, helper layout wrappers,
nonstandard sizes, etc.
===============================================================================
*/

h1.title {
    font-weight: normal;
    text-transform: uppercase;
}

#shopping-bag-page .ui-tabs .tab-heading.first::before {
    font-size: 0;
}

/*  Basket products
---------------------------------------------------------------------------*/

.ui-checkout,
.ui-product-list {
    clear: both;
    margin-bottom: 40px;
}

.ui-checkout .submit-button {
    margin: 0 5px 7px;
}

.ui-product-list .heading {
    font-weight: normal;
    text-transform: uppercase;
}

.ui-product-list h2.heading {
    font-size: var(--font-size-h5);
    padding: 15px 0;
}

/*  Quick order form
---------------------------------------------------------------------------*/

.ui-quick-order-form {
    margin: 30px 0 0;
}

.ui-quick-order-header {
    margin-bottom: 10px;
}

/* Defaults customizations */

.ui-page-content {
    text-align: center;
}

.ui-page-content .ui-order-history {
    text-align: left;
}

.ui-page-content h1.title {
    font-size: var(--font-size-h3);
    font-family: SansOri, SansOriFallback, sans-serif;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    padding: 21px 0px 0px 0px;
}

.ui-page-content .intro {
    padding: 0 15%;
    margin: 0px 0px 50px 0px;
}

/*
    basket table
*/

.total-price .price-box {
    width: 100%;
    display: inline-block;
}

.mobile-off {
    display: none;
}

.mobile-on {
    display: inline;
}

.w-form .k-button {
    width: 298px;
}
/*Product slider*/
.ui-swipe-navigation .menu-slider {
    padding: 0;
}

/* Replacements link */
.oos-popup-link {
    font-size: var(--font-size-caption);
    cursor: pointer;
    text-decoration: underline;
}

.oos-popup-link.notify-me-icon {
    font-size: var(--font-size-icon4);
    text-decoration: none;
}

.notify-me-icon:focus,
.notify-me-icon:hover {
    color: var(--primary-main);
}

/* BP1 */
@media only screen and (max-width: 479px) {
    .k-widget .error-icon {
        margin-left: -25px;
    }
}

/* BP1-3 */
@media only screen and (max-width: 919px) {
    .w-form .k-button {
        width: 100%;
    }

    .w-form iframe {
        width: 100%;
    }

    h1.title {
        font-size: var(--font-size-body1);
    }
}
